import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {SearchComponent} from './pages/search/search.component';
import {DetailComponent} from './pages/detail/detail.component';
import {StatementComponent} from './pages/statement/statement.component';
import {ReportComponent} from './pages/report/report.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'search', component: SearchComponent},
  {path: 'resource/:uuid', component: DetailComponent},
  {path: 'resource', component: DetailComponent},
  {path: 'statement', component: StatementComponent},
  {path: 'report', component: ReportComponent},

  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
