import {Component, OnInit} from '@angular/core';
import {SearchService} from '../../services/search.service';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less']
})
export class NotFoundComponent implements OnInit {

  hotKeys = [];
  q: string;

  constructor(
    private searchService: SearchService,
    private message: NzMessageService
  ) {
  }

  ngOnInit() {
    this.getHotKeys();
  }

  getHotKeys() {
    this.searchService.getHotKeys().subscribe(res => {
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          this.hotKeys = res.data;
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });
  }

  searchHotKeys(item) {
    this.q = item;
    window.location.href = '/search?q=' + this.q;
  }

}
