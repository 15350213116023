import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as $ from 'jquery';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});


$(document).ready(() => {
  $(document).scroll(() => {
    const topHeight = $(document).scrollTop(); // 页面滚动的高度
    // tslint:disable-next-line:radix
    const height = parseInt($('.header').css('height')); // 表示页面滚动到此高度是固定某侧边栏元素，一般是需要固定的侧边栏元素上方的总高度
    if (topHeight < height) {
      // 页面滚动的高度未达到需要固定的高度
      // 需要固定的元素
      $('.main-right .right').css({position: '', top: ''});
    } else {
      // 页面滚动的高度超过需要固定的高度，将position设置为fixed
      $('.main-right .right').css({position: 'fixed', top: height});
    }
  });
});
