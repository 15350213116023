import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchService} from '../../services/search.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less']
})
export class ReportComponent implements OnInit {

  email: string;
  link: string;
  reason: string;
  validateForm: FormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private searchService: SearchService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {
    this.validateForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      link: ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });
  }

  submitForm(value: { email: string, link: string, reason: string }): void {
    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params: Params) => {
      this.link = params.link;
      console.log(this.link);
    });
  }


  report() {
    this.searchService.report(this.email, this.link, this.reason).subscribe(res => {
      console.log(res);
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          this.message.create('success', res.msg);
          this.validateForm.reset();
        }
      } catch (error) {
        console.log(error);
        this.message.create('error', `操作失败，请联系管理员`);
      }

    });
  }
}
