import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SearchService} from '../../services/search.service';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {
  currentPage: number;
  pageSize: number;
  q: string;
  total = 0;
  resources = [];
  exist = true;
  hotKeys = [];
  uuid: string;
  isSpinning = false;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private message: NzMessageService
  ) {
    this.currentPage = 1;
    this.pageSize = 15;
  }

  ngOnInit() {
    // 获取url参数
    this.activatedRoute.queryParams.subscribe((params: Params) => {

      if (sessionStorage.getItem('currentPage')) {
        this.currentPage = parseInt(sessionStorage.getItem('currentPage'));
      } else {
        this.currentPage = 1;
      }

      if (params.q) {
        this.q = params.q;
        sessionStorage.setItem('searchKey', params.q);
        this.search();
        return;
      } else if (sessionStorage.getItem('searchKey')) {
        this.q = sessionStorage.getItem('searchKey');
        this.search();
        return;
      } else {
        this.q = '资料';
        sessionStorage.setItem('searchKey', '资料');
        this.search();
        return;
      }

    });
  }

  getDetail(uuid) {
    this.uuid = uuid;
    sessionStorage.setItem('uuid', this.uuid);
    window.location.href = '/resource';
  }

  getHotKeys() {
    this.searchService.getHotKeys().subscribe(res => {
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          // this.message.create('success', res.msg);
          console.log(res);
          this.hotKeys = res.data;
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });
  }

  paginate(size) {
    console.log(size);
    this.currentPage = size;
    sessionStorage.setItem('currentPage', size);
    window.location.href = '/search';
  }

  search() {
    this.isSpinning = true;
    this.searchService.search(this.q, this.currentPage).subscribe(res => {
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.isSpinning = false;
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          this.isSpinning = false;
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          this.isSpinning = false;
          console.log(res);
          console.log(res.data);
          this.total = res.data.total;
          this.resources = res.data.items;
          if (res.data.items.length > 0) {
            this.exist = true;
          } else if (res.data.items.length <= 0) {
            this.exist = false;
            this.getHotKeys();
          }
          // tslint:disable-next-line:only-arrow-functions
          res.data.items.forEach(function(item) {
            const dirStruct = item.dir_struct;
            let newDirStruct = [];
            newDirStruct = JSON.parse(dirStruct);
            if (Array.isArray(newDirStruct)) {
              if (newDirStruct.length > 10) {
                newDirStruct.length = 10;
              }
            }
            item.dir_struct = newDirStruct;
          });
        }
      } catch (error) {
        this.isSpinning = false;
        this.message.create('error', `操作失败，请联系管理员`);
      }

    });
  }

  searchHotKeys(item) {
    this.q = item;
    this.currentPage = 1;
    sessionStorage.setItem('searchKey', item);
    sessionStorage.setItem('currentPage', String(this.currentPage));
    window.location.href = '/search';
  }
}

