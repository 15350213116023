import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  q: string;

  constructor(private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params: Params) => {
      if (sessionStorage.getItem('searchKey')) {
        this.q = sessionStorage.getItem('searchKey')
      } else {
        this.q = params.q;
        sessionStorage.setItem('searchKey',params.q);
      }
    });
  }

  search() {
    if (this.q) {
      window.location.href = '/search';
      sessionStorage.setItem('searchKey', this.q);
      sessionStorage.setItem('currentPage', '1');
    } else {
      window.location.href = "/search";
      sessionStorage.setItem('searchKey', '资料');
      sessionStorage.setItem('currentPage', '1');
    }

  }
}
