import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {PlatformLocation} from '@angular/common';
import {ElementRef} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {NzMessageService} from 'ng-zorro-antd/message';

import {SearchService} from '../../services/search.service';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.less']
})
export class DetailComponent implements OnInit {

  isVisible = false;
  uuid: string;
  resourceDetail: any;
  dirStruct = [];
  totalSize: string;
  relations = [];
  hotKeys = [];
  q: string;
  existResources: boolean;
  extractCode = '';
  existCode = true;
  isSpinning = true;
  qrcode: any;
  ticket: any;
  isPass = false;
  token: string;
  link: string;
  valid: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: PlatformLocation,
    private searchService: SearchService,
    private commonService: CommonService,
    private elementRef: ElementRef,
    private titleService: Title,
    private metaService: Meta,
    private message: NzMessageService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {

      if (params.uuid) {
        this.uuid = params.uuid;
      } else {
        this.uuid = sessionStorage.getItem('uuid');
      }

    });
    console.log('uuid' + this.uuid);

    this.searchService.checkUrl(this.uuid).subscribe(res => {
      console.log(res);
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          // this.message.create('error', res.msg);
        }
        if (res.status === 100) {
          console.log(res);
          this.valid = res.data.valid;
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });

    this.searchService.getDetail(this.uuid).subscribe(res => {
      this.isSpinning = true;
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.isSpinning = false;
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          if (res.status === 1003) {
            this.isSpinning = false;
            this.existResources = false;
            this.getHotKeys();
          } else {
            this.isSpinning = false;
            // this.message.create('error', res.msg);
          }
        } else if (res.status === 100) {
          this.isSpinning = false;
          if (res.data === '') {
            this.existResources = false;
            this.getHotKeys();
          } else {
            this.existResources = true;
            this.resourceDetail = res.data;
            this.uuid = res.data.uuid;
            this.relations = res.data.relations;
            this.dirStruct = JSON.parse(res.data.dir_struct);
            this.totalSize = this.commonService.byteConvert(res.data.total_size);
            // tslint:disable-next-line:max-line-length
            const desc = `${this.resourceDetail.title} - 网盘资源下载，文件大小: ${this.totalSize},由用户于 ${this.resourceDetail.share_time} 上传到网盘，您可以访问本链接进行下载。`;
            const title = `${this.resourceDetail.title} - 网盘搜索,云盘搜索 - 飞鱼盘搜`;

            this.titleService.setTitle(title);
            this.metaService.updateTag({name: 'description', content: desc});
            this.metaService.updateTag({name: 'og:title', content: title});
            this.metaService.updateTag({name: 'og:description', content: desc});
          }
        }
      } catch (error) {
        this.isSpinning = false;
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });
  }

  openModal(): void {
    this.token = window.localStorage.getItem('token');
    this.getDownloadInfo();
    this.isPass = false;
  }

  checkScan(ticket): void {
    this.searchService.checkScan(ticket).subscribe(res => {
      console.log(res);
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          // this.message.create('error', res.msg);
        } else if (res.status === 100) {
          console.log(res);
          this.isPass = true;
          window.localStorage.setItem('token', res.data.token);
          this.token = res.data.token;
          this.getDownloadInfo();
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });
  }

  getDownloadInfo(): void {
    this.searchService.getDownloadInfo(this.uuid, this.token).subscribe(res => {
      console.log(res);
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000 && res.status !== 1003) {
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          this.isPass = true;
          this.isVisible = true;
          this.link = res.data.link;
          if (res.data.extract_code === '') {
            this.existCode = false;
          } else {
            this.existCode = true;
            this.extractCode = res.data.extract_code;
          }
        } else if (res.status === 1003) {
          // 获取二维码，扫码 (无权限，请先扫码关注)
          this.isPass = false;
          this.getQrode();
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });
  }

  getQrode(): void {
    this.searchService.getQrcode().subscribe(res => {
      console.log(res);
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          console.log(res);
          this.qrcode = res.data.url;
          this.ticket = res.data.ticket;
          this.isVisible = true;
          const that = this;

          const i = setInterval(() => {
            that.checkScan(that.ticket);
            if (that.isPass === true || that.isVisible === false) {
              clearInterval(i);
            }
          }, 1000);
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });
  }

  getHotKeys() {
    this.searchService.getHotKeys().subscribe(res => {
      console.log(res);
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          this.hotKeys = res.data;
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });
  }

  searchHotKeys(item) {
    this.q = item;
    sessionStorage.setItem('searchKey', item);
    window.location.href = '/search';
  }

  reportResource() {
    window.location.href = '/report?link=https://' + window.document.domain + '/resource/' + this.uuid;
  }

  copy() {
    const copyEl = document.querySelector('#detail');
    console.log(copyEl);
    const range = document.createRange();
    range.selectNode(copyEl);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.message.create('success', `复制成功！`);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
