import {Component, OnInit} from '@angular/core';
import {SearchService} from '../../services/search.service';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  q: string;
  hotKeys: string[];
  openInstallDocument = false;
  downloadUrl = '';
  version = '';
  downloadIcon = '';

  constructor(
    private searchService: SearchService,
    private message: NzMessageService
  ) {
  }

  ngOnInit() {
    this.searchService.getHotKeys().subscribe(res => {
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          // this.message.create('success', res.msg);
          console.log(res);
          this.hotKeys = res.data;
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });

    this.searchService.getPluginInfo().subscribe(res => {
      try {
        if (typeof res.status === 'undefined' || typeof res.msg === 'undefined') {
          this.message.create('error', `服务端发生错误，请联系管理员`);
        }
        if (res.status > 1000) {
          this.message.create('error', res.msg);
        } else if (res.status === 100) {
          console.log(res);
          const isNew = res.data.is_new;
          if (isNew) {
            this.downloadIcon = '/assets/img/new.png';
          } else {
            this.downloadIcon = '/assets/img/hot.png';
          }
          this.downloadUrl = res.data.download_url;
          this.version = res.data.version;
        }
      } catch (error) {
        this.message.create('error', `操作失败，请联系管理员`);
      }
    });
  }

  search() {
    if (this.q) {
      window.location.href = '/search';
      sessionStorage.setItem("searchKey", this.q);
      sessionStorage.setItem("currentPage", '1');
    }
  }

  searchHotKeys(item) {
    this.q = item;
    sessionStorage.setItem('searchKey', item);
    this.search();
  }

  download() {
    console.log('下载chrome插件');
    window.location.href = this.downloadUrl;
  }

  openDocument() {
    this.openInstallDocument = true;
  }

  close() {
    this.openInstallDocument = false;
  }

  downloadStore() {
    // tslint:disable-next-line:max-line-length
    window.open('https://chrome.google.com/webstore/detail/%E9%A3%9E%E9%B1%BC%E4%BA%91%E7%9B%98%E5%8A%A9%E6%89%8B/llckjenneniijhiehfmbmlgdekeakbmf?hl=zh-CN');
  }

}



