import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgZorroAntdModule, NZ_I18N, zh_CN} from 'ng-zorro-antd';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';

import {HomeComponent} from './pages/home/home.component';
import {SearchComponent} from './pages/search/search.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {DetailComponent} from './pages/detail/detail.component';
import {StatementComponent} from './pages/statement/statement.component';
import {ReportComponent} from './pages/report/report.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {InterceptorService} from './services/interceptor.service';
import {NotFoundComponent} from './pages/not-found/not-found.component';

import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientJsonpModule} from '@angular/common/http';
import {AdsenseModule} from 'ng2-adsense';

registerLocaleData(zh);

@NgModule({
  declarations: [ // 配置项目运行的组件
    AppComponent,
    HomeComponent,
    SearchComponent,
    FooterComponent,
    HeaderComponent,
    DetailComponent,
    StatementComponent,
    ReportComponent,
    SidebarComponent,
    NotFoundComponent,
  ],
  imports: [ // 配置当前模块运行依赖的其他模块
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-6345122181541905',
      adSlot: 8337631777,
    }),
  ],
  providers: [ // 配置项目所需要的服务
    {provide: NZ_I18N, useValue: zh_CN},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { // 导出，根组件不需要导出，因为不需要被依赖
}
