import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {config} from '../config';
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private readonly apiBase: string;

  constructor(
    private http: HttpClient
  ) {
    this.apiBase = config.apiBase;
  }

  search(q: string, page: number): Observable<any> {
    return this.http.post(`${this.apiBase}/api/index/search`, {
      q,
      page,
    });
  }

  getDetail(uuid: string): Observable<any> {
    return this.http.post(`${this.apiBase}/api/index/getDetail`, {
      uuid,
    });
  }

  getHotKeys(): Observable<any> {
    return this.http.post(`${this.apiBase}/api/index/getHotKeys`, {});
  }

  report(email: string, link: string, reason: string): Observable<any> {
    return this.http.post(`${this.apiBase}/api/index/report`, {
      email,
      link,
      reason,
    });
  }

  getQrcode(): Observable<any> {
    return this.http.post(`${this.apiBase}/api/wechat/getQrcode`, {});
  }

  checkScan(ticket: any): Observable<any> {
    return this.http.post(`${this.apiBase}/api/wechat/checkScan`, {
      ticket,
    });
  }

  getDownloadInfo(uuid: string, token: string): Observable<any> {
    return this.http.post(`${this.apiBase}/api/index/getDownloadInfo`, {
      uuid,
      token,
    });
  }

  checkUrl(uuid: string): Observable<any> {
    return this.http.post(`${this.apiBase}/api/index/checkUrl`, {
      uuid,
    })
  }

  getPluginInfo(): Observable<any> {
    return this.http.post(`${this.apiBase}/api/index/getPluginInfo`, {})
  }

}
